<template>
  <el-container>
    <!--    <el-header class="top_div">-->
    <!--      <div class="search_div_father">-->
    <!--        <common-header title="上海舱单发送"></common-header>-->
    <!--      </div>-->
    <!--    </el-header>-->
    <el-container class="main_center_div" id="main_center_div" v-if="checkPer(['shManifest'])">
      <el-aside class="left_div" width="10%">
        <el-menu :router="true" :default-active="$route.path" class="el-menu-vertical-demo"
                 text-color="black" active-text-color="#409eff" id="leftMenu">
          <template v-for="(item,index) in rocketMenuList" v-if="checkPer(['shManifest:rocket'])">
            <el-menu-item :index="item.index" v-if="!item.subMenu">
              <i :class="item.icon"></i>
              <span slot="title">{{ item.title }}
                <span v-if="item.num&&item.num>0"
                      class="num_p">{{ item.num }}</span></span>
            </el-menu-item>
            <el-submenu v-else :index="item.index">
              <template slot="title">
                <i :class="item.icon"></i>
                <span>{{ item.title }}</span>
              </template>
              <el-menu-item-group>
                <el-menu-item v-for="sub in item.subMenu" :key="sub.index" class="f_w_nor" :index="sub.index">
                  {{ sub.title }}
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
          </template>
          <template v-for="(item,index) in menuList">
            <el-menu-item :key="item.index" :index="item.index" v-if="!item.subMenu">
              <i :class="item.icon"></i>
              <span slot="title">{{ item.title }}
                <span v-if="item.num&&item.num>0"
                      class="num_p">{{ item.num }}</span></span>
            </el-menu-item>
            <el-submenu v-else :index="item.index">
              <template slot="title">
                <i :class="item.icon"></i>
                <span>{{ item.title }}</span>
              </template>
              <el-menu-item-group>
                <el-menu-item v-for="sub in item.subMenu" :key="sub.index" class="f_w_nor" :index="sub.index">
                  {{ sub.title }}
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
          </template>
        </el-menu>
      </el-aside>
      <div class="right_div">
        <router-view></router-view>
      </div>
    </el-container>
    <ShManifestAgencyPage v-else></ShManifestAgencyPage>
  </el-container>
</template>

<script>
import CommonHeader from '@/components/common/CommonHeader'
import ShManifestAgencyPage from "@/views/ShanghaiManifest/components/ShManifestAgencyPage.vue";
import bus from "@/utils/bus.js";
import {mapMutations} from "vuex";
import {getHeadListNum} from "@/api/shanghaiManifest";

export default {
  components: {
    CommonHeader,
    ShManifestAgencyPage
  },
  data() {
    return {
      //系统操作员可查看客户舱单并进行操作
      rocketMenuList: [
        {
          index: '/sh_manifest/all',
          icon: 'el-icon-s-order',
          title: '客户舱单列表',
          num: 0
        },
      ],
      menuList: [
        {
          index: '/sh_manifest',
          icon: 'el-icon-s-order',
          title: '草稿箱',
          num: 0
        },
        {
          index: '/sh_manifest/wait',
          icon: 'el-icon-time',
          title: '待发送',
          num: 0
        },
        {
          index: '/sh_manifest/already',
          icon: 'el-icon-s-promotion',
          title: '已发送'
        },
        {
          index: '/sh_manifest/uandd',
          icon: 'el-icon-delete',
          title: '已删改'
        },
        {
          index: '/sh_manifest/counter',
          icon: 'el-icon-s-check',
          title: '柜台删单'
        },
        {
          index: 'shanghai_sft',
          icon: 'el-icon-location-information',
          title: '收发通',
          subMenu: [
            {
              index: '/shanghai_sft_send',
              icon: '',
              title: '发货人',
            },
            {
              index: '/shanghai_sft_revice',
              icon: '',
              title: '收货人',
            },
            {
              index: '/shanghai_sft_notice',
              icon: '',
              title: '通知人',
            },
            {
              index: '/shanghai_sft_rsn',
              icon: '',
              title: '一键收发通',
            },
          ]
        },
        {
          index: 'shanghai_guide',
          icon: 'el-icon-reading',
          title: '用户指南',
          subMenu: [
            {
              index: '/sh_manifest/guide/agreement',
              icon: '',
              title: '用户协议',
            },
            {
              index: '/sh_manifest/guide/book',
              icon: '',
              title: '操作手册',
            },
            {
              index: "/sh_manifest/guide/delete",
              icon: '',
              title: "删改指南"
            },
            {
              index: "/sh_manifest/guide/business",
              icon: '',
              title: "业务资料"
            }
          ]
        },
        {
          index: 'shanghai_wallet',
          icon: 'el-icon-wallet',
          title: '财务记录',
          subMenu: [
            {
              index: '/pay/chargeHistory',
              icon: '',
              title: '充值记录',
            },
            {
              index: '/pay/accountRecord',
              icon: '',
              title: '消费记录',
            },
            {
              index: '/pay/ticket',
              icon: '',
              title: '开票记录',
            },
          ]
        },
      ],
      offsetTop: 0,
      scrollTop: 0,
    }
  },
  computed: {
    needFixed() {
      if (this.scrollTop > 0) {
        return this.scrollTop >= this.offsetTop - 70;
      } else {
        return false;
      }
    }
  },
  mounted() {
    if (this.checkPer(['shManifest'])) {
      this.offsetTop = document.getElementById("main_center_div").offsetTop;
      window.addEventListener('scroll', this.handleScroll)
    }
    this.init();
  },
  methods: {
    handleScroll() {
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    },
    init() {
      this.getManifestListNum()
      bus.$on('getCountNum', (data) => {
            this.getManifestListNum();
          }
      )
    },
    //获取各状态舱单的数量
    getManifestListNum() {
      const param = {
        showCompanyId: this.$store.state.user.cid ? this.$store.state.user.cid : ''
      }
      getHeadListNum(param).then((res) => {
        if (res.data.data) {
          this.menuList[0].num = res.data.data.count_draft;
          this.menuList[1].num = res.data.data.count_wait;
        }
      })
    }
  },
  beforeDestroy() {
    bus.$off('getCountNum');
    window.removeEventListener('scroll', this.handleScroll)
  },
}
</script>

<style lang="stylus" scoped>
.top_div {
  height: auto !important;
  background-image: linear-gradient(var(--RED-C11C20), var(--RED-79031D), var(--RED-68020F));
}

.search_div_father {
  /deep/ .el-input__inner {
    border: none;
    width: auto;
  }
}

.right_div {
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 1rem;
}

.left_div {
  background: white;
  box-sizing: border-box;
}

.f_w_nor {
  font-weight: normal;
}

.main_center_div {
  width: 100%;
  height: 100%;
}

el-main {
  overflow: hidden;
}

.main_div {
  background: white;
  box-sizing: border-box;
  padding: 1rem;
  width: 100%;
}

.el-menu-vertical-demo {
  font-weight: bold;
  overflow: hidden;
}

.el-menu {
  border-right: solid 1px var(--GRAY-f1f3f6);
}

.el-menu-item.is-active {
  background: var(--GRAY-f1f3f6);
}

.fixed {
  position: fixed;
  top: 70px;
  left: 0;
  right: 0;
  width: 10%;
}

.main_fixed {
  //position: fixed;
  //top: 70px;
}

.num_p {
  text-align: center;
  display: inline-block;
  min-width: 2rem;
  height: 2rem;
  margin-left: 1rem;
  color: white;
  background: var(--RED-C11C20);
  border-radius: 50%;
  padding: 2px;
  line-height: 1.5;
}

.manifest_table_out_div {
  height: 100%;
  display: flex;
  flex-direction: column;
}

</style>
